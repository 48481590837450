import * as React from "react";
import { Link } from "gatsby";
import {
  Button,
  Card,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Message,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import axios from "axios";

const coverageOptions = [
  {
    text: "Auto",
    value: "Auto",
    icon: "car",
  },
  {
    text: "Business",
    value: "Business",
    icon: "briefcase",
  },
  {
    text: "Health",
    value: "Health",
    icon: "first aid",
  },
  {
    text: "Home Owners",
    value: "Home Owners",
    icon: "home",
  },
  {
    text: "Life",
    value: "Life",
    icon: "heartbeat",
  },
];

const GOOGLE_FORM_COVERAGE_ID = "entry.1237532430";
const GOOGLE_FORM_NAME_ID = "entry.498037819";
const GOOGLE_FORM_EMAIL_ID = "entry.2038092767";
const GOOGLE_FORM_PHONE_ID = "entry.1018909966";
const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
const GOOGLE_FORM_ACTION_URL = "https://docs.google.com/forms/d/e/1FAIpQLSc-HCQBeTr8kX9tLeUVg6kZ-LJ0cKZP9uMHkT58Ky_uTdGJUA/formResponse";

class QuotePicker extends React.Component {
  state = { name: "", email: "", phone: "", coverageType: "", success: false, messageError: false };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { name, email, phone, coverageType } = this.state;
    const formData = new FormData();
    formData.append(GOOGLE_FORM_COVERAGE_ID, coverageType);
    formData.append(GOOGLE_FORM_NAME_ID, name);
    formData.append(GOOGLE_FORM_EMAIL_ID, email);
    formData.append(GOOGLE_FORM_PHONE_ID, phone);
    this.setState({
      loading: true,
      messageError: false,
      success: false,
    });
    axios.post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData)
     .then(() => {
       this.setState({
         success: true,
      });
    }).catch(() => {
        this.setState({
          messageError: true,
        });
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });

    this.setState({ submittedName: name, submittedEmail: email });
  }

  render() {
    const { name, email, phone, coverageType, success, messageError } = this.state;

    return (
      <div>
        <h1>Get a Quote</h1>
        <p style={{fontSize: "18px"}}>Use the form below or give us a call at 412-922-8222</p>
        <Form success={success} warning={messageError} onSubmit={this.handleSubmit}>
          <Form.Select label="Coverage Type" name="coverageType" value={coverageType} onChange={this.handleChange} placeholder="Select Coverage Type" selection options={coverageOptions}  />
          <Form.Input required label="Name" placeholder="Name" name="name" value={name} onChange={this.handleChange} />
          <Form.Input
            required
            label = "Email"
            placeholder="Email"
            name="email"
            type="email"
            value={email}
            onChange={this.handleChange}
          />
          <Form.Input
            type="tel"
            label="Phone"
            placeholder="Phone"
            name="phone"
            value={phone}
            onChange={this.handleChange} />
          <Message success header="Quote Submitted" content="You're all set. We'll contact you shortly." />
          <Message warning header="Error" content="There was an issue submitting your quote. Please try again later." />
          <Button type="submit" loading={this.state.loading} primary size="large">
            Get a Quote
            <Icon name="right arrow" />
          </Button>
        </Form>
      </div>
    );
  }
}
export default QuotePicker;
