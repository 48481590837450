import * as React from "react";
import { Button, Divider, Header, Image, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";
import car from "../images/car.jpg";
import QuotePicker from "../components/QuotePicker/QuotePicker";

const InsurancePage = () => {
  return (
    <Container>
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <QuotePicker />
        </Container>
      </Segment>
    </Container>
  );
};

export default withLayout(InsurancePage);
